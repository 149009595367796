<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Informações da Operação</div>
      </v-col>
    </v-row>
    <v-form>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft"
          >Origem do Recurso</v-col
        >
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbOrigemRecurso" name="cmbOrigemRecurso" style="width:40%" v-model="origemRecursoValue">
            <option :key="item" :value="item" v-for="item in origensRecursos">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Modalidade</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbModalidade" name="cmbModalidade" style="width:100%" v-model="modalidade">
            <option :key="item" :value="item" v-for="item in modalidades">{{ item }}</option>
          </select>
        </v-col>
      </v-row>

      <!-- Produto -> origem recurso: FGTS -->
      <v-row class="form-row" no-gutters v-if="isVisibleCmbProduto">
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Produto</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbProduto" name="cmbProduto" style="width:100%" v-model="produto">
            <option :key="item" :value="item" v-for="item in cbProdutos">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses"
          >Você possui convênio?</v-col
        >
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <select id="cmbconvenio" name="cmbconvenio" style="width:40%" v-model="convenio"
            >produtos: [
            <option :key="item" :value="item" v-for="item in simNao">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses"
          >Prazo Total (em meses)</v-col
        >
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <input
            type="text"
            id="prazoTotal"
            name="prazoTotal"
            size="23"
            maxlength="14"
            v-mask="'##############'"
            v-model="prazoTotal"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses"
          >Valor do Imóvel -R$</v-col
        >
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <input
            type="text"
            id="valorImovel"
            name="valorImovel"
            size="23"
            maxlength="14"
            v-currency="currencyOptions"
            v-model.lazy="valorImovel"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses"
          >Prestação necessária para o financiamento - R$</v-col
        >
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <input
            type="text"
            id="prestNecessaria"
            name="prestNecessaria"
            size="23"
            maxlength="14"
            v-currency="currencyOptions"
            v-model.lazy="prestNecessaria"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses"
          >Valor do Financiamento - R$</v-col
        >
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <input
            type="text"
            id="valorFinanciamento"
            name="valorFinanciamento"
            size="23"
            maxlength="14"
            v-currency="currencyOptions"
            v-model.lazy="valorFinanciamento"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses"
          >Tipo de Garantia</v-col
        >
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <select id="cmbTipoGarantia" name="cmbTipoGarantia" style="width:100%" v-model="garantia">
            <option :key="item" :value="item" v-for="item in tiposGarantia">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses">Portabilidade</v-col>
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <select id="portabilidade" name="portabilidade" style="width:40%" v-model="portabilidade"
            >produtos: [
            <option :key="item" :value="item" v-for="item in simNao">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses"
          >Sistema de Amortização</v-col
        >
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <select id="cmbSistAmortizacao" name="cmbSistAmortizacao" style="width:40%" v-model="sistemaAmortizacao">
            <option :key="item" :value="item" v-for="item in sistemasAmortizacao">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row" v-if="!cbindexadores">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses">Indexador</v-col>
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <select id="cmbIndexador" name="cmbIndexador" style="width:40%" v-model="indexador" disabled>
            <option :key="item" :value="item" v-for="item in indexadores">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row no-gutters class="form-row" v-if="cbindexadores">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses">Indexador</v-col>
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <select id="cmbIndexador2" name="cmbIndexador2" style="width:40%" v-model="indexador">
            <option :key="item" :value="item" v-for="item in indexadores">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import { createNamespacedHelpers } from "vuex";
import { mapAvaliacaoRiscoFields } from "../../../store/modules/caixaqui-avaliacao-risco";
import { mapDadosGeraisFields } from "../../../store/modules/dados-gerais";
import { OPTIONS } from "../../../config";
import { isValid, moneySmallerOrEqualsThan, requiredInt, requiredMoney } from "../../../validators";
import { required } from "vuelidate/lib/validators";

const { mapGetters: mapDadosGeraisGetters } = createNamespacedHelpers("dadosGerais");

export default {
  name: "CaixaquiAvaliacaoRiscosInformacoesOperacao",

  data: () => ({
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    currencyOptions: OPTIONS.currency,
    validationMessages: {
      origemRecursoValue: {
        required: "Selecione uma origem do recurso."
      },
      modalidade: {
        required: "Selecione uma modalidade."
      },
      prazoTotal: {
        requiredInt: "Preencha o prazo em meses."
      },
      valorImovel: {
        requiredMoney: "Valor de imóvel inválido."
      },
      prestNecessaria: {
        requiredMoney: "Valor de prestação necessária para o financiamento inválida."
      },
      valorFinanciamento: {
        requiredMoney: "Valor do financiamento inválido.",
        moneySmallerOrEqualsThan: "O valor do financiamento não pode ser maior que o valor do imóvel."
      },
      garantia: {
        required: "Selecione um tipo de garantia."
      },
      sistemaAmortizacao: {
        required: "Selecione um Sistema de Amortização."
      }
    }
  }),
  validations() {
    return {
      origemRecursoValue: { required },
      modalidade: { required },
      prazoTotal: { requiredInt },
      valorImovel: { requiredMoney },
      prestNecessaria: { requiredMoney },
      valorFinanciamento: { requiredMoney, smallerOrEqualsThan: moneySmallerOrEqualsThan(this.valorImovel) },
      garantia: { required },
      sistemaAmortizacao: { required }
    };
  },
  computed: {
    ...mapAvaliacaoRiscoFields({
      origemRecursoValue: "origemRecurso.valor"
    }),
    ...mapAvaliacaoRiscoFields([
      "origemRecurso.modalidade",
      "origemRecurso.produto",
      "origemRecurso.convenio",
      "origemRecurso.prazoTotal",
      "origemRecurso.valorImovel",
      "origemRecurso.prestNecessaria",
      "origemRecurso.valorFinanciamento",
      "origemRecurso.garantia",
      "origemRecurso.portabilidade",
      "origemRecurso.sistemaAmortizacao",
      "origemRecurso.indexador"
    ]),
    ...mapDadosGeraisFields([
      "tiposGarantia",
      "sistemasAmortizacao",
      "simNao",
      "modalidades",
      "origensRecursos",
      "indexadores"
    ]),
    ...mapDadosGeraisGetters(["modalidadePorOrigemRecurso", "produtoPorOrigRecursoAndModalidade"]),
    isVisibleCmbProduto() {
      return this.produtoPorOrigRecursoAndModalidade(this.origemRecursoValue, this.modalidade);
    },
    modalidades() {
      return this.modalidadePorOrigemRecurso(this.origemRecursoValue);
    },
    cbProdutos() {
      return this.produtoPorOrigRecursoAndModalidade(this.origemRecursoValue, this.modalidade);
    },
    cbindexadores() {
      if (this.origemRecursoValue === "SBPE") {
        this.indexador = null;
        return true
      } else {
        this.indexador = 'TR';
        return false;
      }
    }
  },
  watch: {
    origemRecursoValue: function() {
      this.modalidade = null;
      this.produto = null;
    },
    modalidade: function() {
      this.produto = null;
    }
  },
  methods: {
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        //this.$router.push(to);
        if (isValid(this.$v, this.validationMessages)) {
          this.$router.push(to);
        }
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped></style>
